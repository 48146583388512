import type { UserRole } from '@hapstack/db'
import type { PropsWithChildren } from 'react'

import { useAuthenticatedUser } from '~/routes/_dashboard/useAuthenticatedUser'

export const RoleGuard = ({
  role: requiredRole = 'admin',
  children,
}: { role?: UserRole } & PropsWithChildren) => {
  const { role } = useAuthenticatedUser()
  if (role !== requiredRole) return null
  return <>{children}</>
}
